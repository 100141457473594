import { createApp } from "vue";
import VueCarousel from 'vue-carousel';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'bootstrap/dist/css/bootstrap.min.css';
/* Global base css */
import './assets/scss/base/index.scss';
import './assets/scss/index.scss';
import 'bootstrap/dist/js/bootstrap.js';
createApp(App).use(VueCarousel).use(store).use(router).mount("#app");
