import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import LayananView from "@/views/LayananView.vue";
import ProfilView from "@/views/ProfilView.vue";
import KontakView from "@/views/KontakView.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/layanan",
    name: "layanan",
    component: LayananView,
  },
  {
    path: "/profil",
    name: "profil",
    component: ProfilView,
  },
  {
    path: "/kontak",
    name: "kontak",
    component: KontakView,
  },
  {
    path: "/kebijakan-privasi",
    name: "kebijakanPrivasi",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/KebijakanPrivasi.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export default router;
