<template>
  <div class="profile bg-blue-4">
    <!-- MAIN -->
    <main id="main" class="main bg-blue-4" role="main">
      <!-- PROFILE -->
      <div class="profile">
        <!-- COMPANY-->
        <div class="container-md">
          <div class="row company py-3">
            <div class="col-12 col-md order-2 order-md-0 m-auto">
              <div class="text-start">
                <h2 class="col-blue-2">PROFIL KAMI</h2>
                <p class="lead mt-3 mb-3">
                  PT OnKlas Prima Indonesia merupakan perusahaan pengembang
                  sistem terintegrasi di sekolah yang dapat memberikan kemudahan
                  bagi sekolah dan penggunanya untuk mendukung kegiatan belajar
                  dan mengajar dengan memanfaatkan layanan daring yang
                  terintegrasi dengan orang tua / wali murid.
                </p>
              </div>
            </div>
            <div class="col-12 col-md">
              <img
                :src="require('@/assets/office.png')"
                alt="assets/office.png"
                class="img-fluid img-office mb-3"
              />
            </div>
          </div>
        </div>
        <!-- END COMPANY-->
        <!-- VISION-->
        <div class="container-md px-0">
          <div class="row vision p-0 m-0 bg-blue-2">
            <div class="col-12 col-md p-0 m-0 order-2 order-md-0 info m-auto">
              <div class="text-start py-2 py-md-0 px-3">
                <h2 class="col-blue-4">VISI</h2>
                <p class="lead col-blue-4">
                  Menjadi solusi platform pendidikan yang paling berharga dan
                  terlengkap, serta mendukung pertumbuhan ekosistem wirausaha di
                  Indonesia.
                </p>
              </div>
            </div>
            <div class="col-12 col-md p-0 m-0">
              <img
                :src="require('@/assets/img-vision.png')"
                alt="assets/img-vision.png"
                class="img-fluid img-vision w-100"
              />
            </div>
          </div>
        </div>
        <!-- END VISION-->
        <!-- MISSION-->
        <div class="container-md px-0">
          <div class="row mission p-0 m-0 bg-blue-2">
            <div class="col-12 col-md order-0 p-0 m-0">
              <img
                :src="require('@/assets/img-mission.png')"
                alt=""
                class="img-fluid img-mission w-100"
              />
            </div>
            <div class="col-12 col-md order-0 order-md-2 info p-0 m-0 m-auto">
              <div class="text-start py-2 py-md-0 px-3">
                <h2 class="col-blue-4">MISI</h2>
                <ul class="lead col-blue-4">
                  <li>
                    Memberikan layanan terbaik berdasarkan kualitas layanan dan
                    kepuasan pelanggan
                  </li>
                  <li>
                    Menciptakan ekosistem wirausaha di lingkungan sekolah dan
                    dapat digunakan dalam kehidupan sehari-hari
                  </li>
                  <li>
                    Mengelola keuangan perusahaan untuk tumbuh secara positif
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- END MISSION-->
      </div>
      <!-- END PROFILE -->
      <!-- LEGALITY -->
      <div class="container-md legality">
        <div class="row py-3">
          <div class="col-12 col-md-4 text-center m-auto">
            <div class="text-center">
              <img
                :src="require('@/assets/img-legality.png')"
                alt="assets/img-legality.png"
                class="img-fluid img-legality"
              />
            </div>
          </div>
          <div class="col-12 col-md-8 pt-3 text-start">
            <h2 class="col-blue-2">LEGALITAS PERUSAHAAN</h2>
            <div class="row legality-text">
              <div class="col-12 col-md">
                <h4>AKTA PENDIRIAN PERUSAHAAN</h4>
                <p>
                  Nomor 38 tanggal 23 Agustus 2018 <br />
                  Notaris Rina Rustianing Warni, SH di Surabaya
                </p>
              </div>
              <div class="col-12 col-md">
                <h4>PERUBAHAN</h4>
                <p>
                  Nomor 09 tanggal 3 Juli 2019 <br />
                  Notaris Rina Rustianing Warni, SH di Surabaya
                </p>
              </div>
            </div>
            <hr />
            <div class="row legality-text">
              <div class="col-12 col-md">
                <h4>PENGESAHAN KEMENKUMHAM RI</h4>
                <p>
                  PENDIRIAN PERUSAHAAN <br />
                  AHU-0040207.AH.01.01 (2018) <br />
                  Disesetujui / disahkan 27 Agustus 2018
                </p>
              </div>
              <div class="col-12 col-md">
                <h4>PERUBAHAN</h4>
                <p>
                  PERUBAHAN KEMENHUKAM <br />
                  AHU-0035049.AH.01.02 (2019) <br />
                  Disesetujui / disahkan 5 Juli 2019
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
      <!-- END LEGALITY -->
    </main>
    <!-- END MAIN -->
  </div>
</template>

<script>
export default {
  name: "ProfilView",
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
};
</script>

<style lang="scss"></style>
