<template>
    <div class="home bg-blue-4 pb-5 pb-md-3">
      <header id="header" class="header pt-3">
        <div class="container-md">
          <!-- FIRST -->
          <div class="row first">
            <div class="col-12 col-md order-2 m-auto">
              <div class="text-start align-content-center">
                <h2 class="col-blue-2">
                  SOLUSI TEPAT, KEMUDAHAN SISTEM MANAJEMEN SEKOLAH
                </h2>
                <p class="lead mt-3 mb-3 description_onklas">
                  Kami menghadirkan layanan dan Integrasi terbaik
                  <code class="col-blue-1">PPDB</code>,
                  <code class="col-blue-1">PSB</code>,
                  <code class="col-blue-1">Daftar ulang</code>,
                  <code class="col-blue-1">Pembelajaran Online</code>,
                  <code class="col-blue-1">Rekap Absensi</code>,
                  <code class="col-blue-1">Sistem Manajemen</code> dan kebutuhan
                  <code class="col-blue-1"
                    >Transaksi - Pembayaran non tunai</code
                  >
                  di Sekolah!
                </p>
              </div>
            </div>
            <div class="col-12 col-md pl-4 pr-4 order-1 order-md-2">
              <img
                :src="require('@/assets/img-banner.png')"
                alt="img-banner.png"
                class="img-fluid img-banner"
              />
            </div>
          </div>
          <!-- END FIRST -->
        </div>
      </header>
      <section class="second">
        <div class="container-md">
          <!-- SECOND -->
          <div class="row second py-3 py-md-5">
            <div class="col-12 col-md">
              <div class="text-center border rounded-4 p-2 p-md-5">
                <div class="assets-mobile d-md-none">
                  <img
                    :src="require('@/assets/img-tagline-mobile.png')"
                    alt="assets/img-tagline-mobile.png"
                    class="img-fluid img-tagline"
                  />
                </div>
                <h2 class="display-6 col-blue-2">
                  PENDIDIKAN ADALAH INVESTASI TERBESAR NEGARA
                </h2>
                <img
                  :src="require('@/assets/img-tagline.png')"
                  alt="assets/img-tagline.png"
                  class="img-fluid d-none d-md-block align-content-center text-center m-auto"
                />
                <div class="text-center">
                  <div class="d-md-none">
                    <p class="lead mt-3 mb-3">
                      Rp 508,1 Trilyun atau 20% APBN Republik Indonesia Tahun
                      2020. Lebih dari 45,3 Juta Siswa belajar setiap tahunnya
                      mulai dari Sekolah Dasar hingga Sekolah Menengah Atas dan
                      Kejuruan.
                    </p>
                  </div>
                  <p class="lead mt-3 mb-3">
                    Peran sistem dan Aplikasi Digital sangat menunjang untuk
                    membentuk mutu pembelajaran Sekolah Cerdas (Smart School)
                    dan menjadi bagian dari kemajuan Pendidikan di Indonesia.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- END SECOND -->
        </div>
      </section>
      <section class="third">
        <div class="container-md">
          <!-- THIRD -->
          <div class="row mt-5">
            <div class="col-12 col-md order-2 order-md-0">
              <div class="text-left mt-5">
                <h2 class="col-blue-2">
                  KENDALA SEKOLAH MENERAPKAN SMART SCHOOL
                </h2>
                <p class="lead mt-3 mb-3">
                  Hanya di Onklas dengan harga yang terjangkau & sistem yang
                  efisien, kami memenuhi kebutuhan sekolah yang unik & beragam.
                </p>
                <p class="lead mt-3 mb-3">
                  Onklas menghadirkan produk berkualitas melalui berbagai riset
                  di divisi penelitian dan pengembangan bisnis perusahaan sesuai
                  problem yang ada di lapangan. Kami menghadirkan solusi yang
                  menyeluruh untuk bisa digunakan semua sekolah mulai jenjang
                  SD, SMP, SMA dan SMK di seluruh Indonesia.
                </p>
              </div>
            </div>
            <div class="col-12 col-md pl-4 pr-4 device-order-first">
              <img
                :src="require('@/assets/img-tagline2.png')"
                alt="img-tagline2.png"
                class="img-fluid img-tagline2"
              />
            </div>
          </div>
          <!-- END THIRD -->
        </div>
      </section>
      <section class="other bg-blue-1 pb-3">
        <div class="container-md">
          <!-- OFFER -->
          <div class="row">
            <div class="col-12 col-md">
              <div class="text-center mt-5">
                <h2 class="text-white display-6">
                  APA YANG KAMI LAKUKAN UNTUK MEMBANTU
                </h2>
              </div>
            </div>
          </div>
          <div class="row m-4">
            <div class="col-12 col-md offer-item">
              <div class="text-center mt-2 bg-blue-4 rounded-3 p-2 p-md-3">
                <img
                  :src="require('@/assets/img-service1.png')"
                  alt=""
                  class="img-fluid img-offer"
                />
                <h4>
                  Kemudahan pelayanan PPDB, PSB & Daftar Ulang untuk Pelajar
                </h4>
                <p>
                  Sistem layanan kami mendukung pelayanan dan pembayaran
                  pendaftaran untuk Penerimaan Peserta Didik baru, Penerimaan
                  Siswa baru, dan Daftar ulang
                </p>
                <button class="btn btn-outline-primary" type="button">
                  DAFTAR PPDB
                </button>
              </div>
            </div>
            <div class="col-12 col-md offer-item">
              <div class="text-center mt-2 bg-blue-4 rounded-3 p-2 p-md-3">
                <img
                  :src="require('@/assets/img-service2.png')"
                  alt=""
                  class="img-fluid img-offer"
                />
                <h4>Aplikasi Pembelajaran Sekolah, Tugas Belajar & Ujian</h4>
                <p>
                  Pelaksanaan belajar dan ujian secara online untuk sekolah,
                  berbasis aplikasi. Kami hadirkan Layanan Materi pembelajaran,
                  tugas dan ujian online
                </p>
                <button class="btn btn-outline-primary" type="button">
                  DOWNLOAD APLIKASI
                </button>
              </div>
            </div>
            <div class="col-12 col-md offer-item">
              <div class="text-center mt-2 bg-blue-4 rounded-3 p-2 p-md-3">
                <img
                  :src="require('@/assets/img-service3.png')"
                  alt=""
                  class="img-fluid img-offer"
                  src=""
                />
                <h4>Edukasi, Manajemen & Sistem Finansial Untuk Sekolah</h4>
                <p>
                  Dengan Onklas semua bisa mengakses layanan dengan mudah, semua
                  dirancang agar mudah digunakan, mulai dari guru, karyawan,
                  siswa sampai dengan orang tua
                </p>
                <button class="btn btn-outline-primary" type="button">
                  LIHAT LAYANAN
                </button>
              </div>
            </div>
          </div>
          <!-- END OFFER -->
        </div>
      </section>
      <section class="offerlist bg-blue-1">
        <!-- OFFERLIST -->
        <div class="container-fluid mt-5 offer-list">
          <div class="row col-blue-4">
            <div class="col-12 col-md offer-item service-management">
              <div class="text-center mt-5">
                <h4 class="display-headings fw-bold">Manajemen Servis</h4>
                <p>
                  Sistem Tata Usaha, Administrasi, Perpustakaan, Absensi &
                  Inventaris
                </p>
              </div>
            </div>
            <div class="col-12 col-md offer-item service-finance">
              <div class="text-center mt-5">
                <h4 class="display-headings fw-bold">Layanan Keuangan</h4>
                <p>
                  Keuangan Bulanan, Cash Flow, e-Money, Rekap Catatan Keuangan,
                  Tagihan Sekolah
                </p>
              </div>
            </div>
            <div class="col-12 col-md offer-item service-teaching">
              <div class="text-center mt-5">
                <h4 class="display-headings fw-bold">Servis Pengajaran</h4>
                <p>
                  Materi, Kurikulum Sekolah, Tugas, Ujian, Penilaian, Video
                  Pembelajaran
                </p>
              </div>
            </div>
            <div class="col-12 col-md offer-item service-branding">
              <div class="text-center mt-5">
                <h4 class="display-headings fw-bold">Servis Branding</h4>
                <p>
                  Sosial Media, Pembuatan Website Sekolah, Berita Sekolah, Jasa
                  Iklan Sekolah
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- END OFFERLIST -->
      </section>
      <section class="our-client">
        <div class="row w-100">
          <div class="col-12 col-md">
            <div class="text-center mt-5">
              <h2 class="col-blue-2">KLIEN KAMI</h2>
              <p class="px-3 px-md-0">
                Berikut adalah para Klien kami yang
                <b>“Berdedikasi untuk Dunia Pendidikan Indonesia”</b>
              </p>
              <swiper
                :modules="modules"
                :autoplay="{
                  delay: 2500,
                  disableOnInteraction: false,
                }"
                :centered-slides="true"
                :spaceBetween="250"
                :slides-per-view="2"
                :loop="true"
                class="swiper"
              >
                <swiper-slide>
                  <img
                    :src="require('@/assets/client1.png')"
                    alt="client1.png"
                    class="img-client"
                  />
                </swiper-slide>
                <swiper-slide>
                  <img
                    :src="require('@/assets/client2.png')"
                    alt="client2.png"
                    class="img-client"
                  />
                </swiper-slide>
                <swiper-slide>
                  <img
                    :src="require('@/assets/client3.png')"
                    alt="client3.png"
                    class="img-client"
                  />
                </swiper-slide>
                <swiper-slide>
                  <img
                    :src="require('@/assets/client1.png')"
                    alt="client1.png"
                    class="img-client"
                  />
                </swiper-slide>
                <swiper-slide>
                  <img
                    :src="require('@/assets/client2.png')"
                    alt="client2.png"
                    class="img-client"
                  />
                </swiper-slide>
                <swiper-slide>
                  <img
                    :src="require('@/assets/client3.png')"
                    alt="client3.png"
                    class="img-client"
                  />
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </section>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import NavbarHeader from "@/components/NavbarHeader.vue";

export default {
  name: "HomeView",
  setup() {
    return {
      modules: [Autoplay],
    };
  },
  data() {
    return {};
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  components: {
    NavbarHeader,
    Swiper,
    SwiperSlide,
  },
};
</script>

<style>
#header {
  background: linear-gradient(0deg, #e9f5ff 8.84%, #ffffff 31.12%), #ffffff;
}

.description_onklas code {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
}

.swiper-wrapper img {
  width: 700%;
}
@media (min-width: 768px) {
  .swiper-wrapper img {
    width: 200%;
  }
}
@media (min-width: 900px) {
  .swiper-wrapper img {
    width: 180%;
  }
}
@media (min-width: 1147px) {
  .swiper-wrapper img {
    width: auto;
  }
}
</style>
