<template>
  <header class="navbar navbar-expand-lg bg-blue-4 bd-navbar sticky-top">
    <nav class="container-md">
      <a class="navbar-brand" href="#">
        <img
          :src="require('@/assets/onklas-logo-horizontal-new.png')"
          alt="brand-logo"
          class="img-fluid img-brand"
        />
      </a>
      <div class="bd-navbar-toggle">
        <button
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          class="navbar-toggler p-2 border-0"
          data-bs-target="#navbarNav"
          data-bs-toggle="collapse"
          type="button"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div
        id="navbarNav"
        class="collapse navbar-collapse justify-content-between"
      >
        <ul class="navbar-nav mr-auto"></ul>
        <ul class="navbar-nav fw-bold">
          <li
            v-for="(item, index) in listMenu"
            :key="`menu-${index}`"
            class="nav-item"
          >
            <router-link
              :class="{ active: item.active }"
              :to="item.to"
              aria-current="page"
              class="nav-link body-16"
              @click="hideCollapse"
            >
              {{ item.label }}
            </router-link>
          </li>
        </ul>
        <a
          class="btn bg-blue-1 text-white rounded-5"
          href="#downloadApp"
          @click="hideCollapse"
          >Download</a
        >
      </div>
    </nav>
  </header>
</template>
<script>
export default {
  name: "NavbarHeader",
  props: {
    msg: String,
  },
  data() {
    return {
      listMenu: [
        {
          label: "Beranda",
          to: "/",
          active: false,
        },
        {
          label: "Layanan",
          to: "layanan",
          active: false,
        },
        {
          label: "Profil",
          to: "profil",
          active: false,
        },
        /*{
          label: "Produk",
          to: "produk",
          active: false,
        },
        {
          label: "Berita",
          to: "berita",
          active: false,
        },*/
        {
          label: "Kontak",
          to: "kontak",
          active: false,
        },
        /*{
          label: "Bantuan",
          to: "bantuan",
          active: false,
        },*/
        {
          label: "Kebijakan & Privasi",
          to: "kebijakan-privasi",
          active: false,
        },
      ],
    };
  },
  methods: {
    hideCollapse() {
      let nav = document.getElementById(`navbarNav`);
      nav.classList.remove(`show`);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.img_logo {
  width: 25px;
}

.logo-text {
  display: contents;

  span {
    font-size: 16px;
    margin-left: 10px;
  }
}

.img-brand {
  width: 105px;
}
</style>
