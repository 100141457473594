<template>
  <div class="layanan bg-blue-4 pb-5 pb-md-3">
    <!-- MAIN -->
    <main id="main" class="main bg-blue-4" role="main">
      <!-- SERVICE -->
      <div class="container-md service">
        <!-- WELCOME-->
        <div class="row service-wecome">
          <div class="col-12 col-md order-2 order-md-0">
            <div class="text-start mt-5">
              <h2 class="col-blue-2">LAYANAN KAMI</h2>
              <p class="lead mt-3 mb-3">
                The total End to End Solution, Onklas adalah layanan lengkap
                untuk software Sistem Informasi Manajemen Sekolah berbasis web &
                Aplikasi sebagai solusi Administrasi Terpadu untuk Sekolah, yang
                mencakup berbagai modul terintegrasi dan bersifat Multiuser
                sehingga dapat diakses oleh semua Siswa, Guru dan Karyawan di
                sekolah, Layanan kami melingkupi:
              </p>
            </div>
          </div>
          <div class="col-12 col-md pt-3 pt-md-5 text-end">
            <img
              :src="require('@/assets/img-teacher.png')"
              alt="img-teacher.png"
              class="img-fluid img-teacher"
            />
          </div>
          <br />
        </div>
        <!-- END WELCOME-->
        <!-- LIST -->
        <div class="row listall">
          <div class="col-12 col-md img order-2 order-md-0">
            <img
              :src="require('@/assets/img-service-ppdb.png')"
              alt="assets/img-service-ppdb.png"
              class="img-fluid img-service-ppdb"
            />
          </div>
          <div class="col-12 col-md info order-0 order-md-2">
            <div class="text-start mt-5">
              <h4 class="col-blue-2">PPDB & PSB ONLINE</h4>
              <p class="lead mt-3">
                PPDB, PSB dan Mendaftar ulang jadi lebih mudah melalui website
                sekolah yang terintegrasi dengan database sekolah, dilengkapi
                formulir dokumen, pembayaran juga bisa dilakukan di Indomaret,
                Alfamart dan Via Bank.
              </p>
            </div>
          </div>
        </div>
        <div class="row listall">
          <div class="col-12 col-md info device-order-first">
            <div class="text-start mt-5">
              <h4 class="col-blue-2">ABSENSI</h4>
              <p class="lead mt-3">
                Mengintegrasikan mesin absensi fingerprint dan cctv berteknologi
                face recognizer, presensi sekolah semua dalam satu paket dengan
                aplikasi yang terintegrasi dengan Waka Kesiswaan, Kepala sekolah
                dan Wali murid.
              </p>
            </div>
          </div>
          <div class="col-12 col-md pl-4 pr-4 img order-2 order-md-0">
            <img
              :src="require('@/assets/img-service-absense.png')"
              alt="assets/img-service-absense.png"
              class="img-fluid img-service-absense"
            />
          </div>
        </div>
        <div class="row listall">
          <div class="col-12 col-md pl-4 pr-4 img order-2 order-md-0">
            <img
              :src="require('@/assets/img-service-management.png')"
              alt="assets/img-service-management.png"
              class="img-fluid img-service-management"
            />
          </div>
          <div class="col-12 col-md info device-order-first">
            <div class="text-start mt-5">
              <h4 class="col-blue-2">SISTEM MANAJEMEN</h4>
              <p class="lead mt-3">
                Kebutuhan administrasi kegiatan belajar mengajar di Sekolah bisa
                terpantau oleh pihak terkait, Perpustakaan, Inventaris, Kantin
                Sekolah, Koperasi, Tata Usaha, termasuk juga keuangan sekolah
                seperti tagihan dan tunggakan sekolah.
              </p>
            </div>
          </div>
        </div>
        <div class="row listall">
          <div class="col-12 col-md info device-order-first">
            <div class="text-start mt-5">
              <h4 class="col-blue-2">CASHLESS</h4>
              <p class="lead mt-3">
                Kami hadir untuk solusi pembayaran non tunai sekolah (Cashless),
                Klaspay bisa digunakan untuk transaksi di kantin, parkir, bayar
                spp, koperasi, dll. Klaspay adalah layanan pembayaran yang
                terdapat dalam aplikasi Onklas. Klaspay dapat digunakan untuk
                pembayaran semua layanan dengan metode pembayaran online atau
                menggunakan kartu siswa RFID.
              </p>
            </div>
          </div>
          <div class="col-12 col-md pl-4 pr-4 img order-2 order-md-0">
            <img
              :src="require('@/assets/img-service-emoney.png')"
              alt="assets/img-service-emoney.png"
              class="img-fluid img-service-emoney"
            />
          </div>
        </div>
        <div class="row listall">
          <div class="col-12 col-md pl-4 pr-4 img order-2 order-md-0">
            <img
              :src="require('@/assets/img-service-apps.png')"
              alt="assets/img-service-apps.png"
              class="img-fluid img-service-apps"
            />
          </div>
          <div class="col-12 col-md info device-order-first">
            <div class="text-start mt-5">
              <h4 class="col-blue-2">PEMBELAJARAN DAN SOSIAL MEDIA</h4>
              <p class="lead mt-3">
                Pelaksanaan belajar mengajar, tugas sekolah, materi dan ujian
                bisa dilakukan secara online dengan berbasis aplikasi. Kami juga
                menghadirkan layanan ujian online dan tugas online dengan
                menggunakan server sekolah untuk bisa dilakukan serentak semua
                siswa. Aplikasi Onklas juga didukung dengan Sosial Media,
                peminjaman buku perpustakaan, video e-learning, absensi,
                pembayaran dan fitur lengkap lainnya.
              </p>
            </div>
          </div>
        </div>
        <!-- END LIST -->
      </div>
      <!-- END SERVICE -->
      <!-- SCHOOL WEBSITE -->
      <div class="mt-5 school-website bg-blue-2 pb-2 pb-md-5">
        <div class="container-md">
          <div class="row">
            <div class="col-12 col-md text-center p-4">
              <div class="text-center">
                <h2 class="col-blue-4 m-0 p-0">
                  ANDA MEMBUTUHKAN WEBSITE SEKOLAH?
                </h2>
              </div>
            </div>
          </div>
          <div class="row content-website position-relative">
            <div class="col-12 col-md text-center px-0">
              <div class="bg-white mx-1 mx-md-0"></div>
              <div class="text-center content-text col-black-2 mx-3 mx-md-5">
                <img
                  :src="require('@/assets/img-school-website.png')"
                  alt="assets/img-school-website.png"
                  class="img-fluid img-school-website mb-2"
                />
                <p>
                  Di era digital ini, banyak orang menggunakan internet untuk
                  mendapatkan informasi mengenai Sekolah. Apabila sekolah anda
                  mempunyai website yang baik maka Siswa, Guru dan Orang tua
                  bisa mencari informasi tentang Sekolah Anda. <br />
                  Onklas hadir menyediakan jasa pelayanan pembuatan profil
                  sekolah Anda.
                </p>
                <button
                  class="btn btn-outline-primary mb-3 mb-md-3"
                  type="button"
                >
                  SAYA MAU BUAT WEBSITE
                </button>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
      <!-- END SCHOOL WEBSITE -->
      <!-- BROADCAST CONTENT -->
      <div class="mt-5 broadcast-content">
        <div class="row px-0 mx-0" style="background-color: #02b8f5 !important">
          <div class="col-12 col-md info-content order-2 order-md-0 py-3 py-md-3 m-auto">
            <div class="text-center px-md-3">
              <h2 class="col-blue-4">MAU SEKOLAHMU DIKENAL LEBIH LUAS?</h2>
              <p class="col-blue-4 body-16">
                Dengan KlasAds. Kami menyediakan jasa promosi, layanan branding
                di sosial media atau portal terkemuka, tim kami membantu anda
                mencapai target pasar sekolah yang lebih luas sekaligus
                pengiklanan yang efektif dan efisien.
              </p>
              <div class="div-btn-broadcast">
                <button class="btn btn-outline-light align-content-end align-content-md-center" type="button">
                  SAYA MAU BERIKLAN
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 col-md img-content order-0 order-md-2 p-0 m-0">
            <div class="text-center">
              <img
                :src="require('@/assets/img-broadcast.png')"
                alt="assets/img-broadcast.png"
                class="img-fluid img-broadcast w-100 h-auto p-0 m-0 align-content-center"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- END BROADCAST CONTENT -->
    </main>
    <!-- END MAIN -->
  </div>
</template>

<script>
export default {
  name: "LayananView",
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
};
</script>

<style lang="scss">
.school-website {
  .content-text {
    position: relative;
    z-index: 10;
  }
  .bg-white {
    width: -webkit-fill-available;
    @media (max-width: 668px) {
      width: -webkit-fill-available;
    }
    height: -webkit-fill-available;
    position: absolute;
    top: 50px;
    text-align: center;
    background-color: #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border: 2px solid #f2f2f2;
    z-index: 1;
  }
}
</style>
