<template>
  <NavbarHeader />
  <router-view></router-view>
  <!-- FOOTER -->
  <footer id="footer" class="border-top">
    <div class="img-call-me">
      <div class="container-md">
        <div class="row justify-content-center contact-big">
          <div class="col-10 col-md inner-contact bg-blue-1 my-5">
            <h3 class="text-uppercase">Hubungi kami</h3>
            <p>Untuk mencoba Onklas di Sekolah Anda</p>
            <button
              @click="gotoWhatsapp"
              class="btn btn-outline-light"
              type="button"
            >
              KONTAK WHATSAPP
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-blue-4" id="downloadApp">
      <div class="container-md">
        <div class="row download-place py-3 py-md-5">
          <div class="col-12 col-md-8 device-order-second">
            <div class="text-start">
              <h2 class="text-center text-md-start">APLIKASI ONKLAS</h2>
              <div class="text-center d-md-none">
                <img
                  :src="require('@/assets/img-download.png')"
                  alt="img-download.png"
                  class="img-fluid img-brand-footer img-download"
                />
              </div>
              <p class="lead mt-3 mb-3">
                <b>
                  Aplikasi Onklas adalah Sistem Belajar, Sosial Media & Ujian
                  Sekolah #1 Indonesia.
                </b>
                <br />
                Onklas mendukung pertumbuhan pendidikan dan menciptakan
                kemudahan dalam belajar dan telah banyak dipercaya oleh
                berbagai sekolah di Seluruh Indonesia. <br /><br />
                Guru tetap bisa produktif dan kreatif selama mengajar dengan
                sistem pembelajaran daring, tugas materi dan ujian bisa
                dilakukan melalui aplikasi Onklas.
              </p>
              <div class="text-center mt-4">
                <a
                  class="btn btn rounded-5 btn-outline-primary rounded-5"
                  href="https://play.google.com/store/apps/details?id=id.onklas.app&hl=en"
                  target="_blank"
                >
                  DOWNLOAD ONKLAS SISWA
                </a>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-4 d-none d-md-block">
            <img
              :src="require('@/assets/img-download.png')"
              alt="img-download.png"
              class="img-fluid img-download"
            />
          </div>
        </div>
      </div>
    </div>
    <section class="bg-blue-4 pt-3">
      <div class="container-md">
        <div class="row">
          <div class="col-12 col-md">
            <img
              :src="require('@/assets/onklas-logo-vertical-new.png')"
              alt="onklas-logo-vertical-new"
              class="img-fluid img-brand-footer mb-4"
            />
          </div>
          <div class="col-12 col-md text-start">
            <h5>Alamat</h5>
            <ul class="list-unstyled text-small">
              <li class="col-black-4">Kantor Pusat:</li>
              <li class="col-black-3">
                Ruko Mezzanine, Blok A-1 <br />
                Jl. Nginden Semolo no.38-40, Surabaya
              </li>
              <li class="col-black-4">Kantor Cabang:</li>
              <li class="col-black-3">
                Ruko WOW lt. 3 <br />
                Jl. Sawojajar Kav. I blok Apel No. 16,<br />
                Malang
              </li>
            </ul>
          </div>
          <div class="col-12 col-md text-start">
            <h5>Kontak</h5>
            <ul class="list-unstyled text-small">
              <li class="col-black-4">Telepon:</li>
              <li class="col-black-3">082231171212</li>
              <li class="col-black-4">Email:</li>
              <li class="col-black-3">official@onklas.id</li>
            </ul>
          </div>
          <div class="col-12 col-md text-center text-md-start">
            <h5>Partner Pembayaran</h5>
            <img
              :src="require('@/assets/bri-logo.png')"
              alt="bri-logo.png"
              class="payment-logo"
            />
            <img
              :src="require('@/assets/mandiri-logo.png')"
              alt="mandiri-logo.png"
              class="payment-logo"
            />
            <img
              :src="require('@/assets/bni-logo.png')"
              alt="bni-logo.png"
              class="payment-logo"
            />
            <!-- <img src="assets/indomaret-logo.png" class="payment-logo" alt=""><img src="assets/alfamart-logo.png" class="payment-logo" alt=""> -->
            <br /><br />
          </div>
          <div class="col-12 col-md text-center">
            <h5 class="d-none d-md-block">Ikuti Kami</h5>
            <ul
              class="social-icons justify-content-center list-unstyled text-small"
            >
              <li
                v-for="(item, index) in listSocialMedia"
                :key="`sm-${index}`"
              >
                <a
                  class="social-icon col-black-3"
                  :href="item.to"
                  :target="item.target"
                >
                  <i :class="item.icon"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="row bottom">
          <div class="col-12 col-md">
            <p class="m-4">
              Copyright © 2023 PT Onklas Prima Indonesia, All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </section>
  </footer>
  <!-- END FOOTER -->
  <button
    id="myBtn"
    v-show="showScrollTop"
    class="rounded-2"
    @click="scrollTop"
  >
    <img alt="alternative" src="assets/images/up-arrow.png" />
  </button>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";

import NavbarHeader from "@/components/NavbarHeader.vue";

const showScrollTop = ref(false);

const listSocialMedia = [
  {
    to: "https://www.facebook.com/onklasid-113227540421802/",
    icon: "fab fa-facebook-f",
    label: "facebook",
    target: "_blank",
  },
  {
    to: "https://www.instagram.com/onklasofficial/",
    icon: "fab fa-instagram",
    label: "instagram",
    target: "_blank",
  },
  {
    to: "https://www.linkedin.com/in/onklas-id-79ab461b0/",
    icon: "fab fa-linkedin-in",
    label: "linkIn",
    target: "_blank",
  },
  {
    to: "https://twitter.com/officialonklas",
    icon: "fab fa-twitter",
    label: "twitter",
    target: "_blank",
  },
]

const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const gotoWhatsapp = () => {
  let isi = '';
  isi += `Hallo Kak, Saya mau tanya tentang Aplikasi Onklas.%0a`;
  isi += `Sekolah saya lagi membutuhkan Sistem yang cocok untuk Content Management Sistem%0a`;
  window.open('https://api.whatsapp.com/send?phone=6282231171212&text=' + isi, '_blank');
};

const handleScroll = () => {
  showScrollTop.value = window.scrollY > 40;
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
