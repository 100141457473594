<template>
  <div class="profile bg-blue-4">
    <!-- MAIN -->
    <main id="main" class="main bg-blue-4" role="main">
      <!-- HEAD CONTACT -->
      <div class="head-contact container-md py-3">
        <div class="row welcome">
          <div class="col-12 col-md order-2 order-md-0 m-auto">
            <div class="text-start py-2">
              <h2 class="col-blue-2">KONTAK KAMI</h2>
              <p>
                PT OnKlas Prima menyediakan tim Helpdesk dan marketing yang akan
                selalu siap membantu para pengguna Onklas. Komunikasi dengan tim
                Help Desk dapat dilakukan dengan mudah melalui media Whatsapp,
                Email, dan melalui telepon.
              </p>
            </div>
          </div>
          <div class="col-12 col-md">
            <img
              :src="require('@/assets/img-cs.png')"
              alt="assets/img-cs.png" class="img-fluid img-cs" />
          </div>
          <br />
        </div>
      </div>
      <!-- END HEAD CONTACT -->
      <!-- CONTACT METHOD -->
      <div class="contact-method bg-blue-2">
        <div class="row px-2 px-md-0 p-0 m-0">
          <div class="col-12 col-md">
            <div class="text-center mt-5">
              <h2 class="col-blue-4 display-6">CARA MENGHUBUNGI KAMI</h2>
            </div>
          </div>
        </div>
        <div class="row m-4">
          <div class="col-12 col-md mb-3 rounded-3 contact-method-item bg-blue-4">
            <div class="text-center mt-2">
              <img
                :src="require('@/assets/img-contact-wa.png')"
                alt="assets/img-contact-wa.png"
                class="img-fluid img-contact-method"
              />
              <h4 class="col-blue-2">WHATSAPP</h4>
              <p>
                Ingin bertanya lebih lengkap tentang layanan kami ataupun yang
                sudah terdaftar bisa menghubungi kami via Whatsapp untuk
                mendapat bantuan fast response
              </p>
            </div>
          </div>
          <div class="col-12 col-md mb-3 rounded-3 contact-method-item bg-blue-4">
            <div class="text-center mt-2">
              <img
                :src="require('@/assets/img-contact-mail.png')"
                alt="assets/img-contact-mail.png"
                class="img-fluid img-contact-method"
              />
              <h4 class="col-blue-2">EMAIL</h4>
              <p>
                Kirim semua pertanyaan anda seputar Onklas ke email
                offical@onklas.id. Email anda akan kami respon dalam waktu 1×24
                jam sejak kami terima
              </p>
            </div>
          </div>
          <div class="col-12 col-md mb-3 rounded-3 contact-method-item bg-blue-4">
            <div class="text-center mt-2">
              <img
                :src="require('@/assets/img-contact-phone.png')"
                alt="assets/img-contact-phone.png"
                class="img-fluid img-contact-method"
              />
              <h4 class="col-blue-2">TELEPON</h4>
              <p>
                ilahkan menghubungi Call Center kami di nomor 081230905905. Kami
                siap melayani semua keperluan dan pertanyaan anda seputar Onklas
              </p>
            </div>
          </div>
        </div>
        <br />
      </div>
      <!-- END CONTACT METHOD -->
      <!-- CONTACT DETAIL -->
      <div class="contact-detail container-md">
        <div class="row py-3">
          <div class="col-12 col-md pl-4 text-center">
            <img
              :src="require('@/assets/office-room.png')"
              alt="assets/office-room.png"
              class="img-fluid img-office-room"
            />
          </div>
          <div class="col-12 col-md order-2 order-md-0 mt-3">
            <div class="text-start">
              <h4>Kantor Pusat:</h4>
              <p class="lead mb-4">Jl. Semolowaru Elok AB / 26, Surabaya</p>
              <h4>Kantor Cabang:</h4>
              <p class="lead mb-4">
                Ruko WOW lt. 3. Jl. Sawojajar Kav. I blok Apel No. 16, Malang
              </p>
              <h4>Email & Telepon:</h4>
              <p class="lead mb-4">offical@onklas.id | 082231171212</p>
            </div>
          </div>
          <br />
        </div>
      </div>
      <!-- END CONTACT DETAIL -->
    </main>
    <!-- END MAIN -->
  </div>
</template>

<script>
export default {
  name: "KontakView",
  mounted() {
    //window.scrollTo({ top: 0, behavior: "smooth" });
  }
};
</script>

<style lang="scss"></style>
